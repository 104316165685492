<template>
  <div>
    <div class="buttonsContainer">
      <button class="logoButton">
        <img
          style="width:8vw; padding-left: 6px;height: 11vh;"
          class="mallLogoIcon"
          src="../assets/icons/logo-coopharma.png"
        />
      </button>
      <router-link :to="{ name: 'Products' }">
        <button v-bind:class="'ProductsButton'">
          <img class="iconPicture" src="../assets/icons/priceTag.png" />
          <small class="h6 font-weight-light" style="color:white">{{
            $t("navBar.products")
          }}</small>
        </button>
      </router-link>
      <router-link :to="{ name: 'Categories' }">
        <button v-bind:class="'CategoriesButton'">
          <img class="iconPicture" src="../assets/icons/outlineBar.png" />
          <small class="h6 font-weight-light" style="color:white">{{
            $t("navBar.categories")
          }}</small>
        </button>
      </router-link>
      <router-link :to="{ name: 'Orders' }">
        <button v-bind:class="'OrdersButton'">
          <img class="iconPicture" src="../assets/icons/order.png" />
          <small class="h6 font-weight-light" style="color:white">{{
            $t("navBar.orders")
          }}</small>
        </button>
      </router-link>
      <router-link :to="{ name: 'Profile' }">
        <button v-bind:class="'ProfileButton'">
          <img class="iconPicture" src="../assets/icons/perfil.png" />
          <small class="h6 font-weight-light" style="color:white">{{
            $t("navBar.profile")
          }}</small>
        </button>
      </router-link>
      <router-link :to="{ name: 'Reports' }">
        <button v-bind:class="'ReportsButton'">
          <img class="iconPicture" src="../assets/icons/finance.png" />
          <small class="h6 font-weight-light" style="color:white">{{
            $t("navBar.reports")
          }}</small>
        </button>
      </router-link>
      <a href="https://mcsotc.coopharma.app/" target="_blank">
        <button v-bind:class="'ReportsButton'">
          <img
            class="iconPicture"
            src="https://mcsotc.coopharma.app/assets/icon/favicon.png"
          />
          <small class="h6 font-weight-light" style="color:white">MCS</small>
        </button>
      </a>
      <button v-bind:class="'languageButton'" @click="changeLanguage()">
        <img class="iconPicture" :src="''" />
        <small class="h6 font-weight-light" style="color:white">{{
          $t("navBar.language")
        }}</small>
      </button>
      <button v-bind:class="'languageButton'" @click="logout()">
        <img class="iconPicture" :src="''" />
        <small class="h6 font-weight-light" style="color:white">{{
          $t("navBar.logout")
        }}</small>
      </button>
    </div>
  </div>
</template>
<script>
import { i18n } from "@/plugins/i18n";
import Parse from "parse";
export default {
  data() {
    return {
      tabs: [],
      selected: "storeOrders"
    };
  },
  methods: {
    logout() {
      Parse.User.logOut()
        .then(() => {
          this.$router.push("/");
        })
        .catch(err => {
          this.$swal({
            title: "Ha ocurrido un error",
            icon: "error",
            html: err
          });
        });
    },
    changeLanguage() {
      if (i18n.locale === "es") {
        i18n.locale = "en";
      } else {
        i18n.locale = "es";
      }
    }
  }
};
</script>
<style scoped>
* {
  margin: 0px;
  padding: 0px;
}
.buttonsContainer {
  margin: 0px;
  padding: 0px;
  width: 8vw;
  height: 85vh;
  float: left;
  background-color: #1e1e1e;
}

.buttonsContainer:hover {
  margin: 0px;
  padding: 0px;
  width: 8vw;
  height: 85vh;
  float: left;
  background-color: #1e1e1e;
}
.componentContainer {
  width: 90vw;
  height: 100vh;
  float: right;
}
.logoButton {
  /* width: 10vw; */
  /* height: 16.66666666666666666666667vh; */
  border-style: none;
  background-color: #f3f3f3;
}
.ProductsButton,
.CategoriesButton,
.OrdersButton,
.ProfileButton,
.ReportsButton,
.languageButton {
  width: 100%;
  height: 13%;
  border-style: none;
  background-color: #0e9390;
  color: #dbdada;
}
.languageButton {
  height: 12vh;
}
.ProductsButton:hover,
.CategoriesButton:hover,
.OrdersButton:hover,
.ProfileButton:hover,
.ReportsButton:hover,
.languageButton:hover {
  background-color: #024d4b;
}
.iconPicture {
  width: 32%;
  margin: auto;
  display: block;
}
</style>
