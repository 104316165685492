<template>
  <div>
    <div class="topBar">
      <v-toolbar flats height="100%" style="box-shadow:none">
        <v-container>
          <v-row>
            <v-col>
              <v-row>
                <p class="label">
                  {{
                    usuarioFarmacia.admin
                      ? $t("products.administrator")
                      : $t("products.assistant")
                  }}
                  {{ userName }}
                </p>
              </v-row>
              <v-row style="margin-top:0">
                <p>
                  {{ email }}
                </p>
              </v-row>
            </v-col>
            <v-col>
              <div class="form-group" id="categories-list">
                <p class="form-title mb-2" for="exampleInputPassword1">
                  Codigo seguridad: {{ secureCode }}
                </p>
                <p>Numero Secreto: {{ secureNumber }}</p>
              </div>
            </v-col>
            <v-col>
              <img class="profileIcon" :src="pictureUrl" />
            </v-col>
          </v-row>
        </v-container>
      </v-toolbar>
    </div>
  </div>
</template>
<script>
import Parse from "parse";
export default {
  name: "Header",
  data() {
    return {
      usuarioFarmacia: {},
      userName: "",
      email: "",
      pictureUrl: "",
      secureCode: "",
      secureNumber: ""
    };
  },
  mounted() {
    this.secureCode = Parse.User.current().id;
    this.userName = Parse.User.current().get("fullName");
    this.email = Parse.User.current().get("email");
    this.SeeStoreUser();
    this.getStoreId();
  },
  methods: {
    SeeStoreUser() {
      if (JSON.parse(localStorage.getItem("usuarioFarmacia"))) {
        this.usuarioFarmacia = JSON.parse(
          localStorage.getItem("usuarioFarmacia")
        );
      }
    },
    async getStoreId() {
      await Parse.Cloud.run("getStore", {
        userId: Parse.User.current().id
      }).then(result => {
        this.secureNumber = result.get("secureNumber");
        this.pictureUrl = result.get("storePicture").url()
          ? result.get("storePicture").url()
          : "../assets/icons/coopharmaheart.png";
      });
    }
  }
};
</script>
<style scoped>
.topBar {
  /* width: 90vw; */
  height: 10.666667vh;
  background-color: #f3f3f3;
}
.profileIcon {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  float: right;
  /* margin-top: 10px; */
  display: block;
  object-fit: cover;
  object-position: center;
}
.label {
  font-size: 25px !important;
  /* color: #a19c9c !important; */
  font-weight: 300 !important;
}
</style>
